.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.quotes {
  display: flex;
  flex-direction: row;
}

.hidden {
  display: none;
}
