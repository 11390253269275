.container {
    display: flex;
    flex-direction: column;
    max-width: 100em;
    padding: 1em;
    border-radius: 1em;
    box-shadow: 0 1em 2em 0 rgba(0, 0, 0, 0.2);
}

.quote {
    display: flex;
    font-size: calc(10px + 2vmin);
    font-weight: 500;
    background-color: black;
    color: white;
    padding: 1em;
    border-radius: 1em;
}

.author {
    font-size: x-large;
    font-weight: 600;
    align-self: flex-end;
    padding: 0.2em;
}

.jobTitle {
    font-style: italic;
    font-weight: 600;
    display: flex;
    align-self: flex-end;
    padding: 0.2em;
}

.socialMedia {
    display: flex;
    align-self: flex-end;
    gap: 1ch;
}
