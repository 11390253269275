.container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 0.7rem;
}

.paginationUnit {
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #4f4f4f;
  transition: 100ms;
}

.selected {
  background-color: rgb(38, 86, 175);
}
